<template>
  <div>
    <highcharts :options="chartOptions"></highcharts>
  </div>
</template>

<script>
import { Chart } from "highcharts-vue";

export default {
  name: "GraficaEstadoGuias",

  components: {
    highcharts: Chart,
  },

  data() {
    return {
      chartOptions: {
        xAxis: {
          categories: [],
        },

        yAxis: {
          allowDecimals: false,
          min: 0,
          title: {
            text: "Cantidad De Guias",
          },
        },

        exporting: {
          enabled: true,
        },
        title: {
          text: "",
          align: "center",
        },
        subtitle: {
          text: "CANTIDAD DE GUIAS POR ESTADO",
          align: "center",
        },

        series: [
          {
            type: "column",
            name: ["Disponibles"],
            data: [],
            color: "#00C851",
          },
          {
            type: "column",
            name: "Usadas",
            data: [],
            color: "#0099CC",
          },
          {
            type: "column",
            name: "Anuladas",
            data: [],
            color: "#ffbb33",
          },
          {
            type: "column",
            name: "Extraviadas",
            data: [],
            color: "#ff4444",
          },
          {
            type: "column",
            name: "Cerradas",
            data: [],
            color: "#143358",
          },
          {
            type: "column",
            name: "Sin Imprimir",
            data: [],
            color: "#EDEDED",
          },
        ],

        credits: {
          enabled: false,
        },
        hoverBorderWidth: 10,
      },

      animation: {
        animateRotate: false,
        animateScale: true,
      },

      cantidadDisp: "",
      cantidadUsa: "",
      cantidadAnu: "",
      cantidadExt: "",
      cantidadCer: "",
      cantidadImp: "",
      categorias: [],
    };
  },

  methods: {
    getIndex(data) {
      this.chartOptions.xAxis.categories = [];
      this.chartOptions.series[0].data = [];
      this.chartOptions.series[1].data = [];
      this.chartOptions.series[2].data = [];
      this.chartOptions.series[3].data = [];
      this.chartOptions.series[4].data = [];
      this.chartOptions.series[5].data = [];

      Object.entries(data.groupBloques).forEach(([key, blq]) => {
        if (
          !this.$parent.filtros.sitio_id ||
          (this.$parent.filtros.sitio_id &&
            this.$parent.filtros.sitio_id == key)
        ) {
          if (blq.Sitio) {
            this.chartOptions.xAxis.categories.push(blq.Sitio);
          }

          if (blq.cantDisp) {
            this.chartOptions.series[0].data.push(blq.cantDisp);
          }

          if (blq.cantUsa) {
            this.chartOptions.series[1].data.push(blq.cantUsa);
          }

          if (blq.cantAnu) {
            this.chartOptions.series[2].data.push(blq.cantAnu);
          }
          if (blq.cantExt) {
            this.chartOptions.series[3].data.push(blq.cantExt);
          }
          if (blq.cantCer) {
            this.chartOptions.series[4].data.push(blq.cantCer);
          }
          if (blq.cantImp) {
            this.chartOptions.series[5].data.push(blq.cantImp);
          }
        }
      });
    },
  },
};
</script>
